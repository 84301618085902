// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

// A dummy function to check if user is authenticated
// Replace this with your actual authentication logic
const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');  // Check for auth token in localStorage
};

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
