import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-12">
        <Navbar />
      <div className="max-w-2xl w-full space-y-8">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">Welcome to Recruiter's Assistant</h2>
        <p className="text-center text-xl text-gray-700">
          Choose a service to get started.
        </p>

        {/* Menu of Available Services */}
        <div className="mt-8 space-y-4">
          {/* Resume Evaluation Service */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Resume Evaluation</h3>
            <p className="mt-2 text-sm text-gray-600">
              Upload a resume and evaluate it against a job description to find the best fit for your job opening.
            </p>
            <Link
              to="/resume-evaluation"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Get Started
            </Link>
          </div>

          {/* Add more services here as your application grows */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
