import React from 'react';
import Navbar from './Navbar';

const TosPage = () => {
  return (
    <div className="container mx-auto p-4">
        <Navbar />
      <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
      <p className="mb-4">Effective Date: 23, September 2024</p>

      <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By using Recruiter's Assistant, you agree to comply with and be bound by the following terms and conditions. 
        If you do not agree to these terms, you may not use our services.
      </p>

      <h2 className="text-xl font-semibold mb-2">2. Description of Service</h2>
      <p className="mb-4">
        Recruiter's Assistant provides AI-powered resume evaluation and recruitment services. The free tier allows 
        limited usage, while paid subscriptions offer enhanced features. All users must register to access the services.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. User Responsibilities</h2>
      <p className="mb-4">
        You are responsible for maintaining the confidentiality of your account credentials and for all activities that 
        occur under your account. You agree to notify us immediately of any unauthorized use of your account.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Subscriptions and Payments</h2>
      <p className="mb-4">
        Recruiter's Assistant offers subscription-based services. By subscribing, you agree to provide accurate payment 
        information and authorize us to charge the applicable subscription fees to your payment method. Subscription 
        fees are non-refundable.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Use of Service</h2>
      <p className="mb-4">
        You agree not to use Recruiter's Assistant for any unlawful purpose or to violate any applicable laws. You are 
        prohibited from uploading malicious content, such as viruses, or engaging in behavior that compromises the 
        security of our services.
      </p>

      <h2 className="text-xl font-semibold mb-2">6. Intellectual Property</h2>
      <p className="mb-4">
        All content provided by Recruiter's Assistant, including but not limited to software, design, text, graphics, 
        and logos, is the intellectual property of Recruiter's Assistant. You may not reproduce, distribute, or create 
        derivative works from this content without express written permission.
      </p>

      <h2 className="text-xl font-semibold mb-2">7. Limitation of Liability</h2>
      <p className="mb-4">
        To the fullest extent permitted by law, Recruiter's Assistant shall not be liable for any indirect, incidental, 
        special, consequential, or punitive damages arising from your use of the service, including, but not limited to, 
        loss of data or profits.
      </p>

      <h2 className="text-xl font-semibold mb-2">8. Modifications to Terms</h2>
      <p className="mb-4">
        We reserve the right to modify these terms at any time. We will notify you of significant changes by posting a 
        notice on our website or by other appropriate means. Your continued use of the service following the changes 
        indicates your acceptance of the new terms.
      </p>

      <h2 className="text-xl font-semibold mb-2">9. Termination</h2>
      <p className="mb-4">
        We may terminate or suspend your access to Recruiter's Assistant at our discretion, without notice, for conduct 
        that we believe violates these terms or is harmful to other users or to our business interests.
      </p>

      <h2 className="text-xl font-semibold mb-2">10. Governing Law</h2>
      <p className="mb-4">
        These terms and conditions are governed by and construed in accordance with the laws of Pennsylvania, 
        without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the 
        courts located within Pennsylvania to resolve any legal matters arising from the terms.
      </p>

      <p className="mt-8">If you have any questions about these terms, please contact us at support@recruitersassistant.com.</p>
    </div>
  );
};

export default TosPage;
