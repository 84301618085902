import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const LandingPage = () => {
    // Dynamically load Stripe script when component mounts
    useEffect(() => {
        const loadStripeScript = () => {
            const script = document.createElement('script');
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                console.log("Stripe Pricing Table script loaded successfully.");
            };

            script.onerror = () => {
                console.error("Error loading Stripe Pricing Table script.");
            };
        };

        loadStripeScript();
    }, []);

    return (
        <div className="bg-white">
            {/* Navigation */}
            <Navbar />

            {/* Hero Section */}
            <div className="relative bg-white overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                    <span className="block xl:inline">Your AI-powered</span>{' '}
                                    <span className="block text-indigo-600 xl:inline">Recruitment Assistant</span>
                                </h1>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    RecruitersAssistant.com harnesses the power of AI to streamline your hiring process. Evaluate resumes with precision, save time, and find the perfect candidates faster than ever before.
                                </p>
                                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <div className="rounded-md shadow">
                                        <Link
                                            to="/app"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                        >
                                            Get started
                                        </Link>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-3">
                                        <a
                                            href="#how-it-works"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                                        >
                                            Learn more
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="/hero-image.png" alt="AI-powered recruitment process" />
                </div>
            </div>

            {/* Features Section */}
            <div id="features" className="py-12 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Features</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            A better way to hire
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                            Recruiter's Assistant offers a suite of powerful features to transform your hiring process.
                        </p>
                    </div>
                    <div className="mt-10">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        {/* Add an SVG icon here */}
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">AI-Powered Resume Analysis</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Our advanced AI evaluates resumes against job requirements with accuracy.
                                </dd>
                            </div>
                            {/* Add more feature items similarly */}
                        </dl>
                    </div>
                </div>
            </div>

            {/* How It Works Section */}
            <div id="how-it-works" className="bg-gray-50 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">How It Works</h2>
                    <div className="mt-10">
                        <ol className="list-decimal space-y-4 text-lg text-gray-700">
                            <li>Upload a job description and specify the required skills.</li>
                            <li>Submit candidate resumes for analysis.</li>
                            <li>Receive AI-generated evaluations based on the job fit.</li>
                            <li>Make informed hiring decisions faster.</li>
                        </ol>
                    </div>
                </div>
            </div>

            {/* Pricing Section */}
            <div id="pricing" className="bg-gray-100 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Choose your plan
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Select the plan that suits your recruitment needs.
                    </p>
                    <div className="mt-10 grid grid-cols-3 md:grid-cols-1 gap-8">

                        {/* Stripe Pricing Table */}
                        <stripe-pricing-table 
                            pricing-table-id="prctbl_1Q4Llf2Lru9evZ7SHRJScnRF" 
                            publishable-key="pk_live_51Q3K3z2Lru9evZ7SHGkIv4GHO1O6ifkvDAJOqAVNhPMRbzhKzc4ZvJwtYRuKpwy1KQPqsTfVM0OZwaz2PcFJEAj500Rc4wBDNy">
                        </stripe-pricing-table>
                    </div>
                </div>
            </div>

            <Footer />

            <div className="py-8 text-center bg-gray-50">
                <p className="text-lg text-gray-500">Start using Recruiter's Assistant today and revolutionize your hiring process.</p>
            </div>
        </div>
    );
};

export default LandingPage;
