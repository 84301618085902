import React from 'react';
import Navbar from './Navbar.js'

const PrivacyPage = () => {
  return (

    <div className="container mx-auto p-4">
    <Navbar />

      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Effective Date: [Date]</p>

      <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
      <p className="mb-4">
        At Recruiter's Assistant, we are committed to protecting your privacy and safeguarding your personal information. 
        This Privacy Policy outlines how we collect, use, and protect your data when you use our services.
      </p>

      <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
      <p className="mb-4">
        We collect various types of information to provide and improve our services. This may include:
        <ul className="list-disc ml-8 mt-2">
          <li>Personal Information: Name, email address, and other contact details when you register an account.</li>
          <li>Payment Information: If you subscribe to a paid plan, we collect payment information (via secure third-party payment processors like Stripe).</li>
          <li>Usage Data: Information about your interaction with our platform, such as IP address, browser type, and pages visited.</li>
          <li>Resume Data: The content of any resumes or job descriptions you upload to our service for analysis.</li>
        </ul>
      </p>

      <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
      <p className="mb-4">
        We use your data to provide, personalize, and improve our services, including:
        <ul className="list-disc ml-8 mt-2">
          <li>To create and manage your account.</li>
          <li>To process payments and manage subscriptions.</li>
          <li>To analyze resumes against job descriptions, as per your request.</li>
          <li>To communicate with you, such as responding to inquiries or sending service updates.</li>
          <li>To comply with legal obligations and prevent fraud.</li>
        </ul>
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Data Sharing and Disclosure</h2>
      <p className="mb-4">
        We do not sell or rent your personal information to third parties. However, we may share your data in the following situations:
        <ul className="list-disc ml-8 mt-2">
          <li>With trusted service providers who help us operate our platform (e.g., payment processors).</li>
          <li>In response to legal requests or to comply with legal obligations.</li>
          <li>To protect our rights, property, or safety, or the rights, property, or safety of others.</li>
        </ul>
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
      <p className="mb-4">
        We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-semibold mb-2">6. Your Choices</h2>
      <p className="mb-4">
        You have the right to access, update, or delete your personal information at any time. You may also opt-out of receiving marketing communications by following the instructions in those messages or by contacting us directly.
      </p>

      <h2 className="text-xl font-semibold mb-2">7. Cookies</h2>
      <p className="mb-4">
        We use cookies and similar technologies to improve your experience on our platform, analyze usage, and personalize content. You can control cookie preferences through your browser settings.
      </p>

      <h2 className="text-xl font-semibold mb-2">8. Third-Party Links</h2>
      <p className="mb-4">
        Our platform may contain links to third-party websites or services that are not controlled by us. We are not responsible for the privacy practices or content of those third-party sites.
      </p>

      <h2 className="text-xl font-semibold mb-2">9. Changes to this Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website. Your continued use of our services constitutes your acceptance of the new terms.
      </p>

      <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at privacy@recruitersassistant.com.
      </p>
    </div>
  );
};

export default PrivacyPage;
