import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ResumeEvaluator from './components/ResumeEvaluator';
import SignInPage from './components/SignInPage';  // Import Sign In page
import SignUpPage from './components/SignUpPage';  // Import Sign Up page
import ProtectedRoute from './components/ProtectedRoute';
import ProfilePage from './components/ProfilePage';
import HomePage from './components/HomePage';
import ResumeEvaluationPage from './components/ResumeEvaluationPage'; // Ensure this is the page with the evaluation logic
import PrivacyPage from './components/PrivacyPage';
import TosPage from './components/TosPage';
import BlogPage from './components/BlogPage';
import BlogAdminPage from './components/BlogAdminPage';
import BlogDetailPage from './components/BlogDetailPage';
import WaitlistSignup from './components/WaitListSignup';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/app" element={<ProtectedRoute element={<ResumeEvaluator />} />} />
        <Route path="/login" element={<SignInPage />} />  {/* Sign In page route */}
        <Route path="/register" element={<SignUpPage />} /> {/* Sign Up page route */}
        <Route path="/profile" element={<ProfilePage />} /> {/* Sign Up page route */}
        <Route path="/" element={<HomePage />} />
        <Route path="/resume-evaluation" element={<ResumeEvaluationPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/tos" element={<TosPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-admin" element={<BlogAdminPage />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route path="/admin/blog" element={<BlogAdminPage />} />
        <Route path="/waitlist" element={<WaitlistSignup />} />

      </Routes>
    </Router>
  );
}

export default App;
