import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import DOMPurify from 'dompurify';  // Sanitize HTML to prevent XSS attacks
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const MAX_ARTICLES_DISPLAY = 6; // Limit to 6 articles or any number you prefer
  const MAX_CONTENT_LENGTH = 300; // Limit content length for preview

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs`);
        setArticles(response.data.slice(0, MAX_ARTICLES_DISPLAY));  // Limit number of articles
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError('Failed to load articles.');
      }
    };

    fetchArticles();
  }, []);

  const truncateContent = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    return sanitizedContent.length > MAX_CONTENT_LENGTH
      ? sanitizedContent.substring(0, MAX_CONTENT_LENGTH) + '...'
      : sanitizedContent;
  };

  return (
    <div className="bg-white">
      <Navbar />

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900">Blog</h1>

        {error ? (
          <p className="mt-8 text-red-500">{error}</p>
        ) : articles.length > 0 ? (
          <div className="mt-8 grid gap-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-8">
            {articles.map(article => (
              <div key={article._id} className="bg-white overflow-hidden shadow rounded-lg">
                {article.image && (
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/${article.image}`}
                    alt={article.title}
                    className="w-full h-48 object-cover"
                  />
                )}
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-lg font-medium text-gray-900">{article.title}</h2>
                  <div
                    className="mt-2 text-sm text-gray-600"
                    dangerouslySetInnerHTML={{ __html: truncateContent(article.content) }}
                  />
                  <Link
                    to={`/blog/${article._id}`}
                    className="mt-4 block text-indigo-600 hover:text-indigo-900 font-semibold"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-8 text-gray-500">No articles found.</p>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
