import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';
import DOMPurify from 'dompurify';  // Sanitize HTML to prevent XSS attacks

const BlogDetailPage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <p>Loading...</p>;
  }

  return (
    <div className="bg-white">
      <Navbar />

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900">{article.title}</h1>

        {article.image && (
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${article.image}`}
            alt={article.title}
            className="w-full h-auto mt-8 mb-8"
          />
        )}

        <div
          className="text-gray-700 text-lg"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}
        />
      </div>
    </div>
  );
};

export default BlogDetailPage;
