import React, { useState } from 'react';
import axios from 'axios';

const TestDbConnection = () => {
  const [connectionStatus, setConnectionStatus] = useState('');

  const testConnection = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/test-db-connection`);
      setConnectionStatus(`Success: ${JSON.stringify(response.data)}`);
    } catch (error) {
      setConnectionStatus(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <button onClick={testConnection} className="bg-blue-500 text-white px-4 py-2 rounded">
        Test DB Connection
      </button>
      {connectionStatus && <p>{connectionStatus}</p>}
    </div>
  );
};

export default TestDbConnection;
