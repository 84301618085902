import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import TestDbConnection from './TestDbConnection';  // Import the test component

// Load Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProfilePage = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('Free');
  const [sessionId, setSessionId] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch the current user's subscription status from the backend
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
        try {
          const authToken = localStorage.getItem('authToken');
          if (!authToken) {
            console.error('Auth token missing');
            setError('User is not authenticated.');
            return;
          }
          console.log('Auth Token:', localStorage.getItem('authToken'));

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/subscription-status`, {
            headers: { 'Authorization': `Bearer ${authToken}` },
          });
          setSubscriptionStatus(response.data.status);
        } catch (error) {
          console.error('Error fetching subscription status:', error);
          setError('Unable to fetch subscription status.');
        }
      };
    fetchSubscriptionStatus();
  }, []);

  // Handle user upgrade to Pro using Stripe
  const handleUpgrade = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
        email: localStorage.getItem('userEmail'), // Replace with actual user email stored during registration
      });
      setSessionId(response.data.sessionId);
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Unable to start the upgrade process. Please try again.');
    }
  };

  // Redirect to Stripe checkout when sessionId is set
  useEffect(() => {
    if (sessionId) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({ sessionId });
        if (result.error) {
          console.error('Error during Stripe checkout redirect:', result.error.message);
          setError('Failed to proceed with the upgrade.');
        }
      };
      redirectToCheckout();
    }
  }, [sessionId]);

  // Sign out function
  const handleSignOut = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-12">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Your Profile</h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {/* <TestDbConnection /> */}

        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Status</h3>
          <p className="mt-2 text-sm text-gray-600">
            {subscriptionStatus === 'Free'
              ? 'You are on the Free tier. Upgrade to unlock additional features!'
              : `You are subscribed to the ${subscriptionStatus} tier.`}
          </p>

          {subscriptionStatus === 'Free' && (
            <button
              onClick={handleUpgrade}
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Upgrade to Pro
            </button>
          )}

          <button
            onClick={handleSignOut}
            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
