import React, { useState } from 'react';
import axios from 'axios';

const ResumeEvaluator = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [requiredSkills, setRequiredSkills] = useState('');
  const [resume, setResume] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [freeTierLimitReached, setFreeTierLimitReached] = useState(false);

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };

  const handleJobDescriptionChange = (e) => {
    setJobDescription(e.target.value);
  };

  const handleRequiredSkillsChange = (e) => {
    setRequiredSkills(e.target.value);
  };

  const handleResumeUpload = (e) => {
    setResume(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFreeTierLimitReached(false);
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const base64Pdf = e.target.result;
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/evaluate`, {
          jobTitle,
          jobDescription,
          requiredSkills,
          resume: base64Pdf
        });
        setResults(response.data.results);
        setFreeTierLimitReached(response.data.free_tier_limit_reached);
      } catch (error) {
        console.error('Error evaluating resumes:', error);
      }
  
      setLoading(false);
    };
  
    reader.readAsDataURL(resume);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Resume Evaluator</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Job Title:</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={jobTitle}
            onChange={handleJobTitleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Job Description:</label>
          <textarea
            className="w-full p-2 border rounded"
            value={jobDescription}
            onChange={handleJobDescriptionChange}
            rows="5"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Required Skills (comma-separated):</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={requiredSkills}
            onChange={handleRequiredSkillsChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Upload Resumes (PDF):</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleResumeUpload}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          disabled={loading}
        >
          {loading ? 'Evaluating...' : 'Evaluate Resumes'}
        </button>
      </form>
      {results.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Evaluation Results:</h2>
          {freeTierLimitReached && (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
              <p className="font-bold">Free Tier Limit Reached</p>
              <p>You have reached the limit of 5 candidate evaluations for the free tier. Upgrade for unlimited evaluations.</p>
            </div>
          )}
          <ul>
            {results.map((result, index) => (
              <li key={index} className="mb-4 p-4 border rounded">
                <h3 className="font-bold">{result.name}</h3>
                <p>Score: {result.score}%</p>
                <p>Summary: {result.summary}</p>
                <p>Matching Skills: {result.matching_skills}</p>
                <p>Missing Skills: {result.missing_skills}</p>
                <p>Recommendation: {result.recommendation}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ResumeEvaluator;